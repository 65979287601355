<template>
    <b-row>
        <Left>
            <Title heading="Finish Your Saved Quote or Application">
                Don't have a quote yet?&nbsp;
                <b-link to="/">Get your quote here</b-link>
            </Title>
            <RetrieveForm />
        </Left>
        <Right>
            <Coverage />
        </Right>
    </b-row>
</template>

<script>
import Title from '@/components/Common/Title';
import Left from '@/components/Common/Left';
import Right from '@/components/Common/Right';
import Coverage from '@/components/Coverage';
import RetrieveForm from '@/components/RetrieveForm';

export default {
    name: 'Retrieve',
    components: { RetrieveForm, Coverage, Right, Left, Title }
};
</script>

<style scoped></style>

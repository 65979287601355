<template>
    <b-form @submit.prevent="submit">
        <b-form-row>
            <b-col md="6">
                <TextInput
                    :model="form.zipCode"
                    name="zipcode"
                    title="Zip/Postal Code"
                    @inputChanged="val => (form.zipCode = val)"
                />
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col md="6">
                <TextInput
                    :model="form.lastName"
                    name="lastname"
                    title="Last Name"
                    @inputChanged="val => (form.lastName = val)"
                />
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col md="6">
                <TextInput
                    :model="form.email"
                    name="email"
                    title="Email Address"
                    @inputChanged="val => (form.email = val)"
                />
            </b-col>
        </b-form-row>
        <b-form-row class="mt-5">
            <Button text="Retrieve" type="submit" />
        </b-form-row>
    </b-form>
</template>

<script>
import { mapActions } from 'vuex';
import TextInput from '@/components/FormGroups/TextInput';
import Button from '@/components/FormGroups/Button';

export default {
    name: 'RetrieveForm',
    components: { TextInput, Button },
    data: () => ({
        form: {
            zipCode: '',
            lastName: '',
            email: ''
        },
        submitting: false,
        submitError: false
    }),
    methods: {
        ...mapActions(['findSession']),
        submit() {
            this.submitting = true;
            this.findSession(this.form)
                .catch(() => {
                    this.submitError = true;
                })
                .then(() => {
                    this.submitting = false;
                });
        }
    }
};
</script>

<style scoped></style>
